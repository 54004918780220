<template>
  <v-navigation-drawer
    color="white"
    data-test="nav-drawer"
    expand-on-hover
    permanent
    rail
    :width="width"
  >
    <v-list class="pt-0" density="compact" nav>
      <app-nav-item v-for="item in visibleItems" :key="item.label" :item="item" />
    </v-list>
    <template v-if="canManageCompany" v-slot:append>
      <v-list class="pb-8 pt-0" density="compact" nav>
        <app-nav-item :item="companyItem" />
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import AppNavItem from '@/components/app-nav-item'

const ACCOUNTING_FAILURES_REFRESH_INTERVAL = 2 * 60 * 1000 // 2 minutes

export default {
  name: 'app-drawer',
  components: { AppNavItem },
  data() {
    return {
      accountingFailuresTimer: 0,
      accountingFailuresCount: 0,
      width: 224
    }
  },
  computed: {
    ...mapGetters('auth', ['canManageCompany', 'canManageUsers', 'isStaff', 'userCompany']),
    companyItem() {
      return {
        label: 'Company Settings',
        to: { name: 'companies-id', params: { id: this.userCompany?.id } },
        dataTest: 'nav-link-company',
        icon: 'mdi-cog-outline',
      }
    },
    items() {
      return [
        {
          label: 'Home',
          to: { name: 'dashboard' },
          icon: 'mdi-home-outline',
          show: true,
          dataTest: 'nav-link-home',
        },
        {
          label: 'Projects',
          to: { name: 'projects' },
          icon: 'mdi-format-list-checkbox',
          show: true,
          dataTest: 'nav-link-projects',
        },
        {
          label: 'Users',
          to: { name: 'users' },
          icon: 'mdi-account-outline',
          show: this.canManageUsers,
          dataTest: 'nav-link-users',
        },
        {
          label: 'Accounting',
          to: { name: 'accounting-failures' },
          icon: 'mdi-refresh',
          show: this.showAccountingFailureLink,
          dataTest: 'nav-link-accounting-failures',
          countBadge: this.accountingFailuresCount,
        },
        {
          label: 'Suppliers',
          to: { name: 'suppliers' },
          icon: 'mdi-store-outline',
          show: this.isStaff,
          dataTest: 'nav-link-suppliers',
        },
      ]
    },
    showAccountingFailureLink() {
      return this.isStaff
    },
    visibleItems() {
      return this.items.filter(item => item.show)
    },
  },
  mounted() {
    if (this.showAccountingFailureLink) {
      this.refreshAccountingFailures()
      this.accountingFailuresTimer = setInterval(this.refreshAccountingFailures, ACCOUNTING_FAILURES_REFRESH_INTERVAL)
    }
  },
  beforeUnmount() {
    if (this.showAccountingFailureLink) {
      clearInterval(this.accountingFailuresTimer)
    }
  },
  methods: {
    async refreshAccountingFailures() {
      const [poFailureCount, expenseFailureCount] = await Promise.all(
        [this.$api.v1.purchaseOrders.getAccountingFailuresCount(), this.$api.v1.expenses.getAccountingFailuresCount()]
      )
      this.accountingFailuresCount = poFailureCount + expenseFailureCount
    },
  }
}
</script>
