<template>
  <v-timeline-item color="secondary" fill-dot size="x-small" width="100%">
    <template v-slot:icon>
      <div class="state-colors" :style="style" />
    </template>

    <v-alert
      border="start"
      color="secondary"
      density="compact"
      variant="outlined"
      :class="event.is_customer_event ? 'customer' : 'staff'"
    >
      <div class="d-flex justify-space-between">
        <div>
          <div class="text-subtitle-1">
            {{ event.action }}
            <span v-if="event.user">
              by {{ event.user.first_name }} {{ event.user.last_name }}
            </span>
          </div>
          <div class="text-caption">
            {{ timeDelta }} ago &mdash; {{ timestamp }}
          </div>
          <div v-if="context && expanded" class="ml-8 mt-5">
            <order-event-context-line v-for="line in context" v-bind="line" :key="line.label" />
          </div>
        </div>
        <qtm-icon-btn
          v-if="context"
          :icon="expanded ? 'mdi-chevron-up': 'mdi-chevron-down'"
          :title="`${expanded ? 'Hide': 'View'} additional context`"
          @click="toggleExpansion"
        />
      </div>
    </v-alert>
  </v-timeline-item>
</template>

<script>
import { dateTime } from '~/models/filters'
import OrderEventContextLine from '@/components/admin/order-event-context-line'
import TimeDeltaMixin from '@/mixins/time-delta-mixin'

export default {
  name: 'order-event-timeline-item',
  components: { OrderEventContextLine },
  mixins: [TimeDeltaMixin],
  props: {
    event: {
      type: Object,
      required: true
    },
    fallbackColor: {
      type: String,
      default: '#ccc'
    },
    now: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      expanded: false,
    }
  },
  computed: {
    colorFrom() {
      return this.event.state_from ? this.event.state_from.log_color : this.fallbackColor
    },
    colorTo() {
      return this.event.state_to ? this.event.state_to.log_color : this.fallbackColor
    },
    context() {
      if (!this.event.context && !this.event.related_object) {
        return undefined
      }

      let context = []

      if (this.event.related_object) {
        context.push({ label: 'related object', value: this.extractContext(this.event.related_object) })
      }

      if (this.event.context) {
        context = context.concat(this.extractContext(this.event.context))
      }

      return context
    },
    style() {
      return `background: linear-gradient(${this.colorTo}, ${this.colorFrom})`
    },
    timestamp() {
      return dateTime(this.event.timestamp)
    }
  },
  methods: {
    extractContext(item) {
      if (item && typeof item === 'object') {
        return Object.keys(item).map(key => ({
          label: key.replace('_', ' '),
          value: this.extractContext(item[key]),
        }))
      }

      return String(item)
    },
    toggleExpansion() {
      this.expanded = !this.expanded
    }
  },
}
</script>

<style lang="scss" scoped>
$size: 22px;

.state-colors {
  border-radius: 50%;
  height: $size;
  width: $size;
}

.staff {
  background-color: white !important;
}

.customer {
  background-color: lightyellow !important;
}
</style>
