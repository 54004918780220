<template>
  <v-dialog
    v-bind="$attrs"
    fullscreen
    hide-overlay
    no-click-animation
    persistent
  >
    <v-card class="non-scrollable-container" tile>
      <div class="d-flex align-center bg-secondary text-white">
        <qtm-icon-btn color="white" icon="mdi-close" @click="confirmClose = true" />
        <span v-if="email" class="qtm-h2 py-2">
          Create invoice for email: "{{ email.subject }}"
        </span>
      </div>

      <div class="d-flex non-scrollable-container">
        <div v-if="showAttachment" class="attachment-viewer">
          <attachment-viewer
            class="pa-2 mr-2"
            select-text-layer
            :src="documentToPreviewSrc"
            :name="documentToPreviewName"
            @close="showAttachment = false"
          />
        </div>
        <v-card-text
          class="bg-background scrollable-container invoice-form mb-16"
          :class="{'attachment-visible': showAttachment}"
        >
          <qtm-content-block>
            <qtm-input-label v-if="email && email.attachments.length" label="Select Attachments">
              <p class="text-caption">
                The selected attachments will be duplicated and associated to the invoice.
              </p>
              <order-attachments
                v-model="email.attachments"
                v-model:selected-attachments="selectedAttachments"
                allow-preview
                allow-select
                no-upload
                no-delete
                remove-title
                @preview="showDocumentPreview"
              >
                <template v-slot:action="{ attachment }">
                  <attachment-analyzer
                    v-model:items="items"
                    :attachment="attachment"
                    :units="units"
                    @analyzing="showDocumentPreview"
                    @update:items="itemsUpdated"
                  />
                </template>
              </order-attachments>
            </qtm-input-label>
          </qtm-content-block>

          <order-attachments
            v-model="attachments"
            allow-preview
            @preview="showDocumentPreview"
          >
            <template v-slot:action="{ attachment }">
              <attachment-analyzer
                v-model:items="items"
                :attachment="attachment"
                :units="units"
                @analyzing="showDocumentPreview"
                @update:items="itemsUpdated"
              />
            </template>
          </order-attachments>

          <qtm-content-block collapsible title="Invoice info">
            <invoice-information ref="invoiceInformation" :invoice="invoice" :order="order" />
          </qtm-content-block>

          <quote-vendor-info v-if="vendor" ref="vendorInfo" v-model:contact="contact" :order="order" :vendor="vendor" />

          <order-cart
            ref="orderCart"
            v-model:delivery-charge="invoice.delivery_charge"
            v-model:freight-cost-code="invoice.freight_cost_code"
            v-model:freight-tax="invoice.freight_tax"
            v-model:items="items"
            v-model:pickup-charge="invoice.pickup_charge"
            :cost-codes="costCodes"
            delivery-required
            :force-cost-code-select="forceCostCodeSelect"
            order-by="sort_order"
            :taxes="taxes"
            total-price-helper
            :units="units"
            validate
            validate-units
            with-prices
          />
          <div class="text-right">
            <order-total-price-estimate
              :delivery-charge="invoice.delivery_charge"
              delivery-required
              :skus="items"
              :pickup-charge="invoice.pickup_charge"
            />
          </div>
          <order-comment v-model="invoice.comment" />
          <project-authority :project="order.jobsite" />
          <v-divider />
          <div class="text-center">
            <qtm-btn :loading="loading" x-large @click="submit">
              Send for Approval
            </qtm-btn>
          </div>
        </v-card-text>
      </div>
    </v-card>
    <confirmation-dialog v-model="confirmClose" title="Do not create invoice" @confirm="close">
      This data will not be saved, are you sure you want to close?
    </confirmation-dialog>
  </v-dialog>
</template>

<script>
import AttachmentAnalyzer from '@/components/attachments/attachment-analyzer'
import AttachmentViewer from '@/components/attachments/attachment-viewer'
import ConfirmationDialog from '@/components/confirmation-dialog'
import FetchCostCodesMixin from '@/mixins/fetch-cost-codes-mixin'
import FetchTaxesMixin from '@/mixins/fetch-taxes-mixin'
import FetchUnitsMixin from '@/mixins/fetch-units-mixin'
import InvoiceInformation from '@/components/invoices/invoice-information'
import OrderAttachments from '@/components/orders/order-attachments'
import OrderCart from '@/components/orders/order-cart'
import OrderComment from '@/components/orders/order-comment'
import OrderTotalPriceEstimate from '@/components/orders/order-total-price-estimate'
import ProjectAuthority from '@/components/jobsites/project-authority'
import QuoteVendorInfo from '@/components/quotes/quote-vendor-info'

const invoiceTemplate = {
  comment: '',
  date_due: null,
  date_issued: null,
  delivery_charge: null,
  freight_cost_code: '',
  freight_tax: null,
  invoice_number: '',
  pickup_charge: null,
  salesperson: null,
  vendor: null,
}

export default {
  name: 'invoice-create-dialog',
  components: {
    AttachmentAnalyzer,
    AttachmentViewer,
    ConfirmationDialog,
    InvoiceInformation,
    OrderAttachments,
    OrderCart,
    OrderComment,
    OrderTotalPriceEstimate,
    ProjectAuthority,
    QuoteVendorInfo,
  },
  mixins: [FetchCostCodesMixin, FetchTaxesMixin, FetchUnitsMixin],
  props: {
    email: {
      type: Object,
      default: undefined
    },
    order: {
      type: Object,
      required: true
    },
  },
  emits: ['created', 'update:model-value'],
  data() {
    const po = this.order.pos[0]
    let contact = null

    if (po?.supplier_contact) {
      contact = {
        id: po.supplier_contact,
        email: po.supplier_contact_email,
        first_name: po.supplier_contact_first_name,
        last_name: po.supplier_contact_last_name,
        phone: po.supplier_contact_phone,
      }
    }

    return {
      attachments: [],
      confirmClose: false,
      contact,
      documentToPreviewSrc: null,
      documentToPreviewName: null,
      invoice: { ...invoiceTemplate },
      items: [],
      loading: false,
      selectedAttachments: [],
      showAttachment: false,
      vendor: null,
    }
  },
  computed: {
    forceCostCodeSelect() {
      return !!this.order.jobsite?.accounting_id
    },
  },
  mounted() {
    this.fetchVendor()
  },
  methods: {
    close() {
      this.confirmClose = false
      this.$emit('update:model-value', false)
    },
    async fetchVendor() {
      if (!this.order.pos[0]) {
        return
      }

      try {
        this.vendor = await this.$api.v1.vendors.get(this.order.pos[0].supplier)
      }
      catch (error) {
        this.$error.report(error)
      }
    },
    async isValid() {
      const refs = [
        this.$refs.invoiceInformation,
        this.$refs.vendorInfo,
        this.$refs.orderCart,
      ]

      if (!this.items.length) {
        this.$refs.orderCart.addPurchaseItem()
        await this.$nextTick()
      }

      return refs.filter(ref => ref).map(ref => ref.isValid()).every(result => result)
    },
    itemsUpdated() {
      this.$refs.orderCart.$el.scrollIntoView({ behavior: 'smooth' })
    },
    showDocumentPreview(attachment) {
      this.documentToPreviewName = attachment.name
      this.documentToPreviewSrc = attachment.url
      this.showAttachment = true
    },
    async submit() {
      const isFormValid = await this.isValid()
      if (!isFormValid) {
        this.$toast.error('Please correct the errors')
        return
      }

      const payload = {
        attachments: this.attachments.map(attachment => attachment.id),
        comments: this.invoice.comment,
        date_due: this.invoice.date_due?.unix() || null,
        date_issued: this.invoice.date_issued.unix(),
        email_attachments: this.selectedAttachments,
        invoice_number: this.invoice.invoice_number,
        order: this.order.id,
        revision: {
          delivery_charge: this.invoice.delivery_charge,
          freight_cost_code: this.invoice.freight_cost_code,
          freight_tax: this.invoice.freight_tax,
          pickup_charge: this.invoice.pickup_charge,
          poskus: this.items.map(item => ({
            comment: item.comment,
            cost_code: item.cost_code,
            day_rate: item.day_rate,
            description: item.description,
            month_rate: item.month_rate,
            quantity: item.quantity,
            rental_duration: item.rental_duration,
            rental_duration_unit: item.rental_duration_unit,
            tax: item.tax,
            unit: item.unit,
            unit_price: item.unit_price,
            week_rate: item.week_rate,
          }))
        },
      }

      this.loading = true
      try {
        const invoice = await this.$api.v1.invoices.create(payload)
        this.$emit('created', invoice)
        this.$toast.success('Invoice created')
        this.invoice = { ...invoiceTemplate }
        this.items = []
        this.selectedAttachments = []
        this.$emit('update:model-value', false)
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },
  }
}
</script>

<style lang="scss" scoped>
.attachment-viewer {
  flex-shrink: 0;
  margin-top: 30px;
  width: 50%;
  overflow-y: auto;
}

.attachment-visible {
  max-width: 50% !important;
}

.non-scrollable-container {
  height: 100%;
  overflow-y: hidden;
}

.scrollable-container {
  overflow-y: auto;
}

.invoice-form > * {
  margin-bottom: 1rem;
}
</style>
