<template>
  <v-menu v-model="priorityMenu" :disabled="!clickable" offset-y>
    <template v-slot:activator="{ props }">
      <v-chip
        v-bind="{ ...$attrs, ...props }"
        :append-icon="clickable? 'mdi-chevron-down' : undefined"
        class="text-uppercase flex-shrink-0 px-2 mr-1"
        :color="color"
        label
        variant="flat"
      >
        {{ priority }}
      </v-chip>
    </template>
    <v-list v-if="clickable" class="qtm-border">
      <v-list-item v-for="(priorityOption, i) in priorities" :key="i" @click="setPriority(priorityOption)">
        <v-list-item-title class="text-capitalize">
          {{ priorityOption }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
const colorMap = {
  low: 'success',
  medium: 'warning',
  high: 'error',
}

export default {
  name: 'order-priority-chip',
  props: {
    order: {
      type: Object,
      required: true
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      priorityMenu: false,
    }
  },
  computed: {
    color() {
      return colorMap[this.order.priority]
    },
    priorities() {
      return [
        'low',
        'medium',
        'high',
      ]
    },
    priority() {
      return this.order.priority === 'medium' ? 'MED' : this.order.priority
    }
  },
  methods: {
    async setPriority(priority) {
      if (priority === this.order.priority) {
        return
      }

      const previousPriority = this.order.priority
      const updateData = {
        id: this.order.id,
        priority,
      }

      this.order.priority = priority

      try {
        const updatedOrder = await this.$api.v1.rfqs.update(updateData)
        this.$store.commit('admin/updateOrder', updatedOrder)
      }
      catch (error) {
        this.$error.report(error)
        this.order.priority = previousPriority
      }
    }
  }
}
</script>
