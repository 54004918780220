<script>
import moment from 'moment'

const UNITS = ['days', 'hours', 'minutes']

export default {
  name: 'time-delta-mixin',
  computed: {
    timeDelta() {
      const now = this.now || moment()
      const timestamp = this.timestamp

      for (let i = 0; i < UNITS.length; i += 1) {
        const unit = UNITS[i]
        const delta = now.diff(timestamp, unit)

        if (delta) {
          const pluralUnit = delta === 1 ? unit.slice(0, -1) : unit
          return `${delta} ${pluralUnit}`
        }
      }
      return '<1 minute'
    }
  }
}
</script>
