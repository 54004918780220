<template>
  <v-menu content-class="qtm-border" offset-y>
    <template v-slot:activator="{ props }">
      <qtm-btn v-bind="props" secondary>
        Quotes ({{ priceGivenCount }}/{{ quotes.length }} prices given)
        <v-icon location="right">
          mdi-chevron-down
        </v-icon>
      </qtm-btn>
    </template>
    <v-list density="compact">
      <admin-link-list-item v-for="quote in quotes" :key="quote.id" :to="`rfqs/quote/${quote.id}/change/`" with-icon>
        <template v-slot:icon>
          <quote-status-icon :quote="quote" />
        </template>

        <span v-if="quote.vendor">
          {{ quote.vendor.name }}
        </span>
        <span v-else>
          No Vendor
        </span>
      </admin-link-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import AdminLinkListItem from '@/components/admin/admin-link-list-item'
import QuoteStatusIcon from '@/components/admin/quote-status-icon'

export default {
  name: 'quotes-received-summary',
  components: { QuoteStatusIcon, AdminLinkListItem },
  props: {
    quotes: {
      type: Array,
      required: true
    }
  },
  computed: {
    priceGivenCount() {
      return this.quotes.filter(quote => quote.response_received === 'price given').length
    },
  }
}
</script>

<style lang="scss" scoped>
.align-top {
  vertical-align: top;
}
</style>
