<template>
  <v-list-item class="admin-link-list-item" :href="url" target="_blank" v-bind="$attrs">
    <template v-if="withIcon" v-slot:prepend>
      <slot name="icon" />
    </template>

    <v-list-item-title>
      <slot />

      <v-icon color="mid-light-grey" size="x-small">
        mdi-open-in-new
      </v-icon>
    </v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  name: 'admin-link-list-item',
  props: {
    apiLink: {
      type: Boolean,
      default: false
    },
    to: {
      type: String,
      required: true,
    },
    withIcon: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    url() {
      const method = this.apiLink ? 'getBackendURL' : 'getAdminURL'

      return this.$api.v1[method](this.to)
    }
  }
}
</script>

<style scoped lang="scss">
.v-icon {
  margin-left: 3px;
  position: relative;
  top: -5px;
}
</style>
