<template>
  <v-menu content-class="qtm-border" offset-y>
    <template v-slot:activator="{ props }">
      <qtm-btn v-bind="props" secondary>
        {{ number }}
        <v-icon location="right">
          mdi-chevron-down
        </v-icon>
      </qtm-btn>
    </template>
    <v-list dense>
      <v-list-item nuxt target="_blank" :to="{name: 'purchase-orders-id-change-order', params: {id}}">
        <v-list-item-title>
          Edit

          <v-icon class="external-link" size="x-small">
            mdi-open-in-new
          </v-icon>
        </v-list-item-title>
      </v-list-item>

      <admin-link-list-item :to="`rfqs/purchaseorder/${id}/change/`">
        Admin
      </admin-link-list-item>

      <admin-link-list-item api-link :to="`pos/${id}/pdf/`">
        Download PDF
      </admin-link-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import AdminLinkListItem from '@/components/admin/admin-link-list-item'

export default {
  name: 'purchase-order-links',
  components: { AdminLinkListItem },
  props: {
    id: {
      type: Number,
      required: true
    },
    number: {
      type: String,
      required: true
    }
  },
}
</script>

<style lang="scss" scoped>
.v-icon.external-link {
  margin-left: 3px;
  position: relative;
  top: -5px;
}
</style>
