<template>
  <v-card class="qtm-border" variant="flat">
    <v-card-title>
      QTM Buyer Notes
    </v-card-title>
    <v-card-text>
      <qtm-textarea v-model="order.qtm_buyer_notes" :maxlength="maxTextareaLength" />
      <qtm-btn secondary @click="saveNote">
        Save
      </qtm-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { MAX_TEXTAREA_LENGTH } from '@/constants'

export default {
  name: 'order-buyer-notes',
  props: {
    maxTextareaLength: {
      type: Number,
      default: MAX_TEXTAREA_LENGTH,
    },
    order: {
      type: Object,
      required: true
    }
  },
  methods: {
    async saveNote() {
      const orderData = {
        id: this.order.id,
        qtm_buyer_notes: this.order.qtm_buyer_notes
      }

      try {
        const updatedOrder = await this.$api.v1.rfqs.update(orderData)
        this.$store.commit('admin/updateOrder', updatedOrder)
        this.$toast.success('Note updated')
      }
      catch (error) {
        this.$error.report(error)
      }
    }
  }
}
</script>
