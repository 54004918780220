<template>
  <span>
    <v-menu v-model="showWidget" content-class="qtm-border">
      <template v-slot:activator="{ props }">
        <v-badge
          color="primary"
          :content="unreadCount"
          offset-x="4"
          offset-y="6"
          :model-value="!!unreadCount"
          data-test="unread-count"
        >
          <v-badge
            color="secondary"
            :content="unassociatedCount"
            offset-x="4"
            offset-y="38"
            :model-value="!!unassociatedCount"
            data-test="unassociated-count"
          >
            <v-badge
              color="interactive"
              :content="bouncedCount"
              offset-x="41"
              offset-y="6"
              :model-value="!!bouncedCount"
              data-test="bounced-count"
            >
              <v-badge
                color="cyan"
                :content="unreadGeneralInboxCount"
                offset-x="41"
                offset-y="38"
                :model-value="!!unreadGeneralInboxCount"
                data-test="unread-general-inbox-count"
              >
                <v-btn
                  v-bind="props"
                  class="email-widget-btn"
                  color="interactive"
                  data-test="widget-icon"
                  icon="mdi-email"
                  outlined
                />
              </v-badge>
            </v-badge>
          </v-badge>
        </v-badge>
      </template>
      <v-card class="unread-emails-menu">
        <div v-if="unreadCount">
          <v-list class="section-header">
            <v-list-item>
              <v-list-item-title class="text-secondary">
                UNREAD EMAILS
              </v-list-item-title>
            </v-list-item>
          </v-list>
          <v-divider />
          <v-list>
            <v-list-item v-for="order in groupedUnreadEmails.keys()" :key="order" class="pl-0">
              <v-list-item-title class="pl-3" data-test="group-header">
                <span class="text-caption">Received for:</span>
                <span v-if="order" class="text-subtitle-1">
                  {{ getOrderTitle(order) }}
                </span>
                <span v-else class="text-subtitle-1">
                  General inbox (all companies)
                </span>
              </v-list-item-title>
              <v-list>
                <v-list-item
                  v-for="email in groupedUnreadEmails.get(order)"
                  :key="email.id"
                  @click.stop="openEmailInDialog(email)"
                >
                  <template v-slot:prepend>
                    <v-icon color="primary" dark size="small">
                      mdi-email
                    </v-icon>
                  </template>
                  <v-list-item-title data-test="email-subject">
                    <span class="truncate" :title="email.subject">{{ email.subject }}</span>
                  </v-list-item-title>
                  <v-list-item-subtitle data-test="email-from">
                    <span class="text-caption">From:</span>
                    <span class="text-subtitle-2">{{ email.from_address }}</span>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="email.is_general_inbox" data-test="email-to">
                    <span class="text-caption">To:</span>
                    <span class="text-subtitle-2">{{ email.recipient }}</span>
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-list-item>
          </v-list>
        </div>
        <div v-if="unreadGeneralInboxCount">
          <v-list class="section-header">
            <v-list-item>
              <v-list-item-title class="text-secondary">
                GENERAL INBOX UNREAD EMAILS
              </v-list-item-title>
            </v-list-item>
          </v-list>
          <v-divider />
          <v-list>
            <v-list-item v-for="recipient in groupedUnreadGeneralInboxEmails.keys()" :key="recipient" class="pl-0">
              <v-list-item-title class="pl-3">
                <span class="text-caption">Received for:</span>
                <span class="text-subtitle-1">{{ recipient }}</span>
              </v-list-item-title>
              <v-list>
                <v-list-item
                  v-for="email in groupedUnreadGeneralInboxEmails.get(recipient)"
                  :key="email.id"
                  @click.stop="openEmailInDialog(email)"
                >
                  <template v-slot:prepend>
                    <v-icon color="cyan" dark size="small">
                      mdi-email
                    </v-icon>
                  </template>
                  <v-list-item-title>
                    <span class="truncate" :title="email.subject">{{ email.subject }}</span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <span class="text-caption">From:</span>
                    <span class="text-subtitle-2">{{ email.from_address }}</span>
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-list-item>
          </v-list>
        </div>
        <div v-if="unassociatedCount">
          <v-list class="section-header">
            <v-list-item>
              <v-list-item-title class="text-secondary">
                UNASSOCIATED EMAILS
              </v-list-item-title>
            </v-list-item>
          </v-list>
          <v-divider />
          <v-list>
            <v-list-item v-for="recipient in groupedUnassociatedEmails.keys()" :key="recipient" class="pl-0">
              <v-list-item-title class="pl-3">
                <span class="text-caption">Received for:</span>
                <span class="text-subtitle-1">{{ recipient }}</span>
              </v-list-item-title>
              <v-list>
                <v-list-item
                  v-for="email in groupedUnassociatedEmails.get(recipient)"
                  :key="email.id"
                  @click.stop="showAssociationDialogForEmail(email)"
                >
                  <template v-slot:prepend>
                    <v-icon color="secondary" dark size="small">
                      mdi-email
                    </v-icon>
                  </template>
                  <v-list-item-title>
                    <span class="truncate" :title="email.subject">{{ email.subject }}</span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <span class="text-caption">From:</span>
                    <span class="text-subtitle-2">{{ email.from_address }}</span>
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-list-item>
          </v-list>
        </div>
        <div v-if="bouncedCount">
          <v-list class="section-header">
            <v-list-item>
              <v-list-item-title class="text-secondary">
                BOUNCED EMAILS
              </v-list-item-title>
            </v-list-item>
          </v-list>
          <v-divider />
          <v-list>
            <v-list-item
              v-for="email in bouncedEmails"
              :key="email.id"
              @click.stop="openEmailInDialog(email)"
            >
              <template v-slot:prepend>
                <v-icon color="interactive" dark size="small">
                  mdi-email-outline
                </v-icon>
              </template>
              <v-list-item-title>
                <span class="truncate" :title="email.subject">{{ email.subject }}</span>
              </v-list-item-title>
              <v-list-item-subtitle>
                <span class="text-caption">To:</span>
                <span class="text-subtitle-2">{{ email.to_addresses }}</span>
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </div>
      </v-card>

      <move-email
        v-if="associatingEmail"
        v-model="showAssociationDialog"
        :email="associatingEmail"
        open-order-after-moving
        @close="associatingEmail=null"
      />
    </v-menu>
    <dialog-view-email-detail v-model="openedEmail" />
  </span>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import MoveEmail from '@/components/admin/emails/move-email'
import DialogViewEmailDetail from '@/components/admin/emails/dialog-view-email-detail'

// for email interval, choose something not an exact multiple of order interval (1 min)
// so they don't frequently hit at the same time
const INBOX_REFRESH_INTERVAL = 57 * 1000 // 57 seconds

export default {
  name: 'emails-widget',
  components: { MoveEmail, DialogViewEmailDetail },
  data() {
    return {
      showWidget: false,
      associatingEmail: null,
      openedEmail: null,
      refreshInboxTimer: null,
    }
  },
  computed: {
    ...mapState('admin', {
      allOrders: 'orders',
      unassociatedEmails: 'unassociatedEmails',
      unreadEmails: 'unreadEmails',
      unreadGeneralInboxEmails: 'unreadGeneralInboxEmails',
      bouncedEmails: 'bouncedEmails',
      emailsFetched: 'emailsFetched',
    }),
    unreadOrders() {
      const orderIds = this.unreadEmails.map(email => email.rfq)
      return this.allOrders.filter(order => orderIds.includes(order.id))
    },
    unreadCount() {
      return this.unreadEmails ? this.unreadEmails.length : 0
    },
    unreadGeneralInboxCount() {
      return this.unreadGeneralInboxEmails ? this.unreadGeneralInboxEmails.length : 0
    },
    unassociatedCount() {
      return this.unassociatedEmails ? this.unassociatedEmails.length : 0
    },
    bouncedCount() {
      return this.bouncedEmails ? this.bouncedEmails.length : 0
    },
    showAssociationDialog() {
      return !!this.associatingEmail
    },
    groupedUnassociatedEmails() {
      return this.groupBy(this.unassociatedEmails, 'recipient')
    },
    groupedUnreadEmails() {
      return this.groupBy(this.unreadEmails, 'rfq')
    },
    groupedUnreadGeneralInboxEmails() {
      return this.groupBy(this.unreadGeneralInboxEmails, 'recipient')
    },
  },
  mounted() {
    if (!this.emailsFetched) {
      this.refreshInbox()
      this.markEmailsFetched()
    }
    this.refreshInboxTimer = setInterval(this.refreshInbox, INBOX_REFRESH_INTERVAL)
  },
  beforeUnmount() {
    clearInterval(this.refreshInboxTimer)
  },
  methods: {
    ...mapMutations({
      markEmailsFetched: 'admin/markEmailsFetched',
    }),
    openEmailInDialog(email) {
      this.showWidget = false
      this.openedEmail = email
    },
    showAssociationDialogForEmail(email) {
      this.associatingEmail = email
    },
    getOrderTitle(id) {
      const order = this.allOrders.find(checkedOrder => checkedOrder.id === id)
      return order ? `${order.qtm_reference_number}: ${order.reference_name}` : ''
    },
    groupBy(list, field) {
      const map = new Map()
      list.forEach((email) => {
        const key = email[field]
        let array = map.get(key)
        if (!array) {
          array = []
          map.set(key, array)
        }
        array.push(email)
      })
      return map
    },
    async refreshInbox() {
      try {
        const lists = {
          unassociated: [],
          unread: [],
          bounced: [],
          unreadGeneralInbox: []
        }
        const emails = await this.$api.v1.notifications.email.getInbox()

        emails.forEach(email => lists[this.getEmailClassification(email)].push(email))

        this.$store.commit('admin/setUnassociatedEmails', lists.unassociated)
        this.$store.commit('admin/setUnreadEmails', lists.unread)
        this.$store.commit('admin/setUnreadGeneralInboxEmails', lists.unreadGeneralInbox)
        this.$store.commit('admin/setBouncedEmails', lists.bounced)
      }
      catch (error) {
        this.$error.report(error)
      }
    },
    getEmailClassification(email) {
      let type
      if (email.email_type === 'inbound') {
        if (!email.rfq && !email.is_general_inbox) {
          type = 'unassociated'
        }
        else if (email.tracking_status === 'delivered') {
          type = email.is_general_inbox ? 'unreadGeneralInbox' : 'unread'
        }
      }
      else if (email.email_type === 'outbound') {
        if (['bounced', 'rejected'].includes(email.tracking_status)) {
          type = 'bounced'
        }
      }
      return type
    },
  }

}
</script>

<style scoped>
  .email-widget-btn {
    border: 1px solid rgb(var(--v-theme-light-grey));
  }
  .unread-emails-menu {
    max-width: 500px;
    max-height: 500px;
    overflow-y: auto;
  }
  .unread-count {
    color: white;
    font-weight: 600;
    font-size: 25px;
    text-align: right;
    padding-left: 3px;
  }
  .section-header {
    background-color: rgb(var(--v-theme-background));
    font-weight: bold;
    padding: 0;
  }
</style>
