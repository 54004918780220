<template>
  <v-list-item
    active-class="active-item"
    :data-test="item.dataTest"
    :exact="item.exact"
    :to="item.to"
  >
    <template v-slot:prepend>
      <v-badge
        :content="item.countBadge"
        :model-value="!!item.countBadge"
        color="red"
        overlap
        location="bottom"
      >
        <v-icon class="item-icon mr-8">
          {{ item.icon }}
        </v-icon>
      </v-badge>
    </template>
    <v-list-item-title class="item-content">
      {{ item.label }}
    </v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  name: 'app-nav-item',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
}
</script>

<style lang="scss" scoped>
$active-color: rgb(var(--v-theme-interactive));
$base-color: rgb(var(--v-theme-mid-grey));

.child-item .item-content {
  font-weight: normal;
}

.child-item,
.item-content,
.item-icon {
  color: $base-color;
  transition: color 0.3s swing;
}

.active-item,
.active-item .item-content,
.active-item .item-icon {
  color: $active-color;
}
</style>
