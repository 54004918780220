<template>
  <v-btn
    v-if="order.canSendNotification"
    color="secondary"
    size="x-small"
    variant="outlined"
    @click="open"
  >
    Send Reminder
    <qtm-dialog-card v-model="confirm" title="Send Reminder Notification?">
      A reminder notification will be sent to
      <qtm-autocomplete v-model="user" hide-details :item-title="userName" :items="users" :loading="loading" />
      <template v-slot:actions>
        <v-spacer />
        <qtm-btn :loading="loading" tertiary @click="confirm = false">
          Cancel
        </qtm-btn>
        <qtm-btn :loading="loading" @click="sendNotification">
          Ok
        </qtm-btn>
      </template>
    </qtm-dialog-card>
  </v-btn>
</template>

<script>
export default {
  name: 'reminder-notification-btn',
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      confirm: false,
      loading: false,
      user: this.order.owner.id,
      users: [],
    }
  },
  methods: {
    async fetchTeam() {
      this.loading = true
      try {
        const users = await this.$api.v1.users.list({ jobsite: this.order.jobsite.id })

        users.sort((a, b) => a.first_name.localeCompare(b.first_name) || a.last_name.localeCompare(b.last_name))
        this.users = users
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },
    open() {
      this.fetchTeam()
      this.confirm = true
    },
    async sendNotification() {
      this.loading = true
      try {
        await this.$api.v1.rfqs.sendStateNotification(this.order.id, { user: this.user })
        this.$toast.success('Notification sent')
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
      this.confirm = false
    },
    userName(user) {
      let item = user

      if (typeof user === 'number') {
        item = this.users.find(u => u.id === user)
      }

      if (!item) {
        return ''
      }

      const append = item.id === this.order.owner.id ? ' (default)' : ''

      return `${item.first_name} ${item.last_name}${append}`
    },
  }
}
</script>
