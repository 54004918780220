<template>
  <qtm-skeleton v-if="loading" />
  <qtm-content-block v-else>
    <v-row>
      <v-col class="py-5" cols="3">
        <qtm-input-label label="User type">
          <qtm-select
            v-model="filter"
            single-line
            :items="['All', 'Staff', 'Customer']"
          />
        </qtm-input-label>
      </v-col>
    </v-row>
    <v-timeline density="compact" side="end">
      <order-event-timeline-item v-for="log in filteredLogs" :key="log.id" :event="log" :now="now" />
      <v-timeline-item v-if="!logs.length" fill-dot size="small">
        No events have been recorded for this order
      </v-timeline-item>
    </v-timeline>
  </qtm-content-block>
</template>

<script>
import OrderEventTimelineItem from '@/components/admin/order-event-timeline-item'

export default {
  name: 'order-event-timeline',
  components: { OrderEventTimelineItem },
  props: {
    order: {
      type: Object,
      required: true
    },
    visible: {
      type: Boolean,
      default: true
    },
    now: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      loading: false,
      logs: [],
      nowUpdateInterval: undefined,
      filter: 'All'
    }
  },
  computed: {
    filteredLogs() {
      if (this.filter === 'Staff') {
        return this.logs.filter(l => !l.is_customer_event)
      }
      if (this.filter === 'Customer') {
        return this.logs.filter(l => l.is_customer_event)
      }
      return this.logs
    }
  },
  watch: {
    visible(isVisible) {
      if (isVisible) {
        this.fetchLogs()
      }
    }
  },
  mounted() {
    if (this.visible) {
      this.fetchLogs()
    }
  },
  methods: {
    async fetchLogs() {
      this.loading = true
      try {
        this.logs = await this.$api.v1.rfqs.eventLog(this.order)
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    }
  }
}
</script>
