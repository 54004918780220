<template>
  <qtm-autocomplete
    v-model="order.agent"
    dense
    hide-details
    :item-title="userName"
    :items="users"
    :loading="loading"
    placeholder="Assign To"
    return-object
    @update:model-value="updateAgent"
  >
    <template v-slot:no-data>
      <v-list-item>
        No available agents
      </v-list-item>
    </template>
  </qtm-autocomplete>
</template>

<script>
export default {
  name: 'order-assign-user',
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      users: [],
    }
  },
  mounted() {
    this.fetchAgents()
  },
  methods: {
    async fetchAgents() {
      this.loading = true
      try {
        const users = await this.$api.v1.users.list({ permission: 'qtm_can_act_as_buyer' })

        users.sort((a, b) => a.first_name.localeCompare(b.first_name) || a.last_name.localeCompare(b.last_name))
        this.users = users
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },

    async updateAgent({ id }) {
      if (!id) {
        return
      }

      const updateData = {
        id: this.order.id,
        agent: id,
      }

      this.loading = true
      try {
        const updatedOrder = await this.$api.v1.rfqs.update(updateData)
        this.$store.commit('admin/updateOrder', updatedOrder)
        this.$toast.success('Agent assigned')
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },

    userName(user) {
      return `${user.first_name} ${user.last_name}`
    },
  }
}
</script>
