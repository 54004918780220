<template>
  <v-chip
    v-if="order.cancelled"
    v-bind="$attrs"
    class="cancelled-chip"
    color="error"
    data-test="cancelled-chip"
    label
    variant="flat"
  >
    Cancelled
  </v-chip>
</template>

<script>
export default {
  name: 'cancelled-chip',
  props: {
    order: {
      type: Object,
      required: true,
    }
  }
}
</script>

<style lang="scss" scoped>
.cancelled-chip {
  padding: 1px 6px 0px 6px;
  margin: 0px 6px 0px 6px;
}
</style>
