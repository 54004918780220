<template>
  <div>
    <b>
      {{ label }}:
    </b>
    <div v-if="isArray">
      <order-event-context-line v-for="item in value" v-bind="item" :key="item.label" class="ml-5" />
    </div>
    <span v-else>
      {{ value }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'order-event-context-line',
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: [Array, String],
      default: ''
    },
  },
  computed: {
    isArray() {
      return Array.isArray(this.value)
    }
  },
}
</script>
