<template>
  <v-dialog
    v-model="showActionDialog"
    :fullscreen="fullscreen"
    :max-width="fullscreen ? undefined : '100rem'"
    persistent
    scrollable
  >
    <v-card v-if="activeOrder" class="action-dialog">
      <div class="dialog-buttons">
        <div v-if="company">
          <nuxt-link target="_blank" :to="{ name: 'companies-id', params: { id: activeOrder.owner.company.id } }">
            <v-img :src="activeOrder.owner.company.logo" max-height="60" max-width="100" />
          </nuxt-link>
          <div class="text-mid-grey" v-text="activeOrder.owner.company.purchasing_email" />
        </div>
        <qtm-icon-btn
          color="mid-grey"
          icon="mdi-refresh"
          :loading="loading"
          title="Refresh"
          @click="refreshOrder"
        />
        <qtm-icon-btn
          color="mid-grey"
          :icon="fullscreen ? 'mdi-window-restore' : 'mdi-window-maximize'"
          title="Resize"
          @click="toggleFullscreen"
        />
        <qtm-icon-btn
          color="mid-grey"
          icon="mdi-close"
          title="Close"
          @click="showActionDialog = false"
        />
      </div>

      <v-card-title
        :class="{ 'cancelled-order': activeOrder.cancelled, 'integrated-background': integrated }"
      >
        <order-priority-chip clickable :order="activeOrder" size="small" />
        <span class="mr-1">
          {{ activeOrder.reference_name }}
        </span>
        <order-type-chip clickable :order="activeOrder" size="small" />
        <cancelled-chip :order="activeOrder" size="small" />
      </v-card-title>

      <v-card-subtitle :class="{ 'integrated-background': integrated }">
        <div class="text-mid-grey">
          {{ activeOrder.qtm_reference_number }} - {{ activeOrder.state ? activeOrder.state.internal_name : '' }}
          <span v-if="activeOrder.transition_timestamp">
            - {{ timeDelta }} in state
          </span>
        </div>
        <admin-link :to="`rfqs/order/${activeOrder.id}/change/`">
          Open in Admin
        </admin-link>
        <reminder-notification-btn v-if="activeOrder.owner" :order="activeOrder" />
      </v-card-subtitle>

      <v-divider class="mb-4" />

      <v-card-text v-if="!activeOrder.lastFullRefresh">
        <qtm-skeleton />
      </v-card-text>
      <v-card-text v-else class="dialog-content pa-2">
        <order-action-sidebar v-if="showSidebar" class="action-dialog-sidebar" :order="activeOrder" />

        <div v-if="showAttachment" class="action-dialog-attachmet-viewer">
          <attachment-viewer
            ref="pdfViewer"
            class="pa-2 mr-2"
            outlined
            select-text-layer
            :src="documentToPreviewSrc"
            :name="documentToPreviewName"
            @close="showAttachment = false"
          />
        </div>

        <div class="action-dialog-component flex-grow-1 pr-4">
          <v-tabs v-model="tab" bg-color="background" class="text-mid-light-grey" color="interactive">
            <v-tab
              v-for="t in tabs"
              :key="t.key"
              class="text-uppercase"
              selected-class="text-interactive v-tab--selected"
            >
              {{ t.label }}
            </v-tab>
          </v-tabs>
          <v-window v-model="tab" class="bg-background">
            <v-window-item key="main">
              <component
                :is="contentComponent"
                ref="activeComponent"
                :order="activeOrder"
                @preview="showDocumentPreview($event)"
              />
            </v-window-item>
            <v-window-item key="inbound-emails">
              <view-emails :order="activeOrder" type="inbound" :visible="tab === 1" />
            </v-window-item>
            <v-window-item key="outbound-emails">
              <view-emails :order="activeOrder" type="outbound" :visible="tab === 2" />
            </v-window-item>
            <v-window-item key="attachments">
              <view-attachments :order="activeOrder" @preview="showDocumentPreview($event)" />
            </v-window-item>
            <v-window-item key="event-log">
              <order-event-timeline :now="now" :order="activeOrder" :visible="tab === 4" />
            </v-window-item>
          </v-window>
        </div>
      </v-card-text>

      <v-divider />

      <v-card-actions class="justify-space-between">
        <common-actions :order="activeOrder" />
        <order-state-actions
          :execute-hook="executeActionHook"
          :order="activeOrder"
          @refresh-component="refreshComponent"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'
import AdminLink from '@/components/admin/admin-link'
import CancelledChip from '@/components/admin/cancelled-chip'
import CommonActions from '@/components/admin/actions/common-actions'
import OrderTypeChip from '@/components/admin/order-type-chip'
import AttachmentViewer from '@/components/attachments/attachment-viewer'
import ReminderNotificationBtn from '@/components/admin/reminder-notification-btn'
import OrderActionSidebar from '@/components/admin/order-action-sidebar'
import OrderEventTimeline from '@/components/admin/order-event-timeline'
import OrderPriorityChip from '@/components/admin/order-priority-chip'
import OrderStateActions from '@/components/admin/order-state-actions'
import TimeDeltaMixin from '@/mixins/time-delta-mixin'
import ViewAttachments from '@/components/admin/view-attachments'
import ViewEmails from '@/components/admin/emails/view-emails'

// 1 minute
const nowUpdateInterval = 60 * 1000

export default {
  name: 'order-action-dialog',
  components: {
    AdminLink,
    CancelledChip,
    CommonActions,
    OrderTypeChip,
    AttachmentViewer,
    ReminderNotificationBtn,
    OrderActionSidebar,
    OrderEventTimeline,
    OrderPriorityChip,
    OrderStateActions,
    ViewAttachments,
    ViewEmails,
  },
  mixins: [TimeDeltaMixin],
  data() {
    return {
      documentToPreviewSrc: null,
      documentToPreviewName: null,
      fullscreen: false,
      loading: false,
      now: moment(),
      nowUpdateTimer: undefined,
      showAttachment: false,
      tab: 0,
    }
  },
  computed: {
    ...mapGetters('auth', ['isStaff']),
    ...mapState('admin', ['activeOrder']),

    company() {
      return this.activeOrder?.owner?.company
    },

    integrated() {
      return !!this.activeOrder?.jobsite?.accounting_id
    },

    showActionDialog: {
      get() {
        return !!this.activeOrder && this.isStaff
      },

      set() {
        this.showAttachment = false
        this.documentToPreviewSrc = null
        this.documentToPreviewName = null
        this.$store.commit('admin/activateOrder', undefined)
      }
    },
    showSidebar() {
      return !this.showAttachment
    },
    contentComponent() {
      if (this.activeOrder.state) {
        const component = this.activeOrder.state.dialog_component

        if (typeof component === 'function') {
          return component(this.activeOrder)
        }
        return component
      }
      return undefined
    },
    tabs() {
      return [
        { label: 'Main', key: 'main' },
        { label: 'Inbox', key: 'inbound-emails' },
        { label: 'Sent Emails', key: 'outbound-emails' },
        { label: 'Attachments', key: 'attachments' },
        { label: 'Event Log', key: 'event-log' },
      ]
    },
    timestamp() {
      return this.activeOrder ? moment(this.activeOrder.transition_timestamp * 1000) : undefined
    },
  },
  watch: {
    activeOrder: {
      immediate: true,
      async handler(newOrder, oldOrder) {
        if (this.$route.query.activeOrder?.toString() !== newOrder?.id?.toString() && newOrder?.id !== oldOrder?.id) {
          this.$router.replace({
            ...this.$route,
            query: {
              activeOrder: this.activeOrder?.id,
            }
          })
        }

        if (!newOrder) {
          this.tab = 0
          return
        }
        if (oldOrder && (newOrder.id === oldOrder.id) && newOrder.lastFullRefresh) {
          return
        }
        try {
          const order = await this.$api.v1.rfqs.get(this.activeOrder.id)
          this.$store.commit('admin/updateOrder', order)
        }
        catch (error) {
          this.$error.report(error)
        }
      }
    }
  },
  mounted() {
    this.nowUpdateTimer = setInterval(() => { this.now = moment() }, nowUpdateInterval)
    if (this.$route.query.activeOrder) {
      this.$store.commit('admin/activateOrder', { id: parseInt(this.$route.query.activeOrder, 10) })
    }
  },
  beforeUnmount() {
    clearInterval(this.nowUpdateTimer)
  },
  methods: {
    executeActionHook(hookName, action, abort) {
      const component = this.$refs.activeComponent
      if (!component || !component[hookName]) {
        return undefined
      }
      return component[hookName](action, abort)
    },
    async refreshComponent() {
      const activeComponent = this.$refs.activeComponent

      if (activeComponent && activeComponent.refresh) {
        // Allow the order to be set before refreshing components that need it
        await this.$nextTick()
        activeComponent.refresh()
      }
    },
    async refreshOrder() {
      if (this.activeOrder) {
        this.loading = true
        try {
          const refreshedOrder = await this.$api.v1.rfqs.get(this.activeOrder.id)
          this.$store.commit('admin/updateOrder', refreshedOrder)
          await this.refreshComponent()
        }
        catch (error) {
          this.$error.report(error)
        }
        this.loading = false
      }
    },
    showDocumentPreview(attachment) {
      this.documentToPreviewName = attachment.name
      this.documentToPreviewSrc = attachment.url
      this.showAttachment = true
    },
    async toggleFullscreen() {
      this.fullscreen = !this.fullscreen
    }
  }
}
</script>

<style lang="scss" scoped>
.action-dialog {
  background-color: rgb(var(--v-theme-background));
}
.dialog-buttons {
  display: flex;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.v-card .dialog-content {
  color: inherit;
}

.order-type-chip {
  margin-left: 1rem;
}

.admin-link {
  font-weight: bold;
}

.v-card .dialog-content {
  color: inherit;
  display: flex;
  padding: 0 0 1rem 0.25rem;
}

.action-dialog-sidebar {
  overflow-y: auto;
  max-width: 20rem;
  flex-shrink: 0;
  margin-right: 1rem;
}

.action-dialog-attachmet-viewer {
  flex-shrink: 0;
  overflow-y: auto;
  width:48%;
}

.action-dialog-component {
  overflow-y: auto;
  min-width: 50%;
}

.cancelled-order {
  border-top: solid 6px rgb(var(--v-theme-error));
}

.integrated-background {
  background-color: rgb(var(--v-theme-sand));
}

.v-tabs-items {
  padding-top: 10px;
}
</style>
