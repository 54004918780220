<template>
  <v-menu location="start" content-class="qtm-border">
    <template v-slot:activator="{ props }">
      <v-btn
        v-bind="props"
        class="mx-2"
        color="interactive"
        icon
        size="x-small"
        variant="flat"
      >
        <v-icon size="large">
          mdi-account
        </v-icon>
      </v-btn>
    </template>
    <user-menu-items />
  </v-menu>
</template>

<script>
import UserMenuItems from '@/components/users/user-menu-items'

export default {
  name: 'user-menu',
  components: { UserMenuItems },
}
</script>

<style lang="scss" scoped>
</style>
