<template>
  <div class="common-actions d-flex">
    <qtm-btn secondary target="_blank" :to="{ name: 'orders-id', params: { id: order.id } }">
      Order
    </qtm-btn>
    <div v-if="order.quotes && order.quotes.length">
      <quotes-received-summary :quotes="order.quotes" />
    </div>

    <div v-if="order.hasPurchaseOrderLinks">
      <purchase-order-links
        v-for="poLink in order.purchaseOrderLinks"
        :id="poLink.link.params.id"
        :key="poLink.link.params.id"
        :number="poLink.label"
      />
    </div>
  </div>
</template>

<script>
import PurchaseOrderLinks from '@/components/admin/actions/purchase-order-links'
import QuotesReceivedSummary from '@/components/admin/actions/quotes-received-summary'

export default {
  name: 'common-actions',
  components: { PurchaseOrderLinks, QuotesReceivedSummary },
  props: {
    order: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.common-actions > *:not(:last-child) {
  margin-right: 0.5rem;
}
</style>
